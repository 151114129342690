import React from 'react';
import LazyLoad from 'react-lazyload';


const LAZY_HIDDEN = {
  opacity: 0,
  transition: 'opacity .6s linear',
  display: 'block',
}

const LAZY_VISIBLE = {
  ...LAZY_HIDDEN,
  opacity: 1,
}

export default class CustomLazyLoad extends LazyLoad {

  static defaultProps = {
    ...LazyLoad.defaultProps,
    once: true,
    height: 500,
  }

  state = {
    status: null,
  }

  componentDidUpdate = () => {
    if (this.visible && this.state.status !== 'done') {
      this.timer = setTimeout(x => this.setState({status: 'done'}), 2000);
    }
  }

  componentWillUnmount = () => {
    try {
      clearTimeout(this.timer);
    } catch(err){};
  }

  render() {
    return this.state.status === 'done' ? super.render() : (
      <span
        style={this.visible ? LAZY_VISIBLE : LAZY_HIDDEN}
        className={`lazyload--${this.visible ? 'loaded' : 'pending'}`}>
        {super.render()}
      </span>
    )

  }

}
